import { ReactNode } from "react";
import Menu from "components/Menu";
import { styled } from "@mui/material/styles";
import Header from "components/Header";
import Footer from "components/Footer";
import CustomScrollbar from "components/Scrollbar";
import useDeviceType, { DeviceType } from "lib/useDeviceType"; // useDeviceTypeフックをインポートする
import mainBGI from "assets/images/KV_back.jpg";

type TemplateMainProps = {
  children: ReactNode;
};

export const MainWidth = "680px";

// レイアウトスタイル
const DivParent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: theme.spacing(3),
  backgroundImage: `url(${mainBGI})`,
  backgroundAttachment: "fixed", // 背景画像を固定
  backgroundSize: "cover",
  backgroundPosition: "center",
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(0),
  },
}));
const DivContent = styled("div")(({ theme }) => ({
  maxWidth: "1080px",
  width: "100%",
  margin: "auto",
  display: "flex",
  flexFlow: "row",
  justifyContent: "center",
  [theme.breakpoints.up("lg")]: {
    justifyContent: "space-between",
  },
}));
const DivMain = styled("div")(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  flexFlow: "column",
  gap: theme.spacing(3),
  padding: theme.spacing(1),
  maxWidth: "680px",
  width: "100%",
  boxSizing: "border-box",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(3),
  },
}));

export default function Main({ children }: TemplateMainProps) {
  // useDeviceTypeフックを呼び出して、デバイスの種類を取得する
  const deviceType = useDeviceType();
  //console.log(deviceType);
  return (
    <DivParent>
      <Header></Header>
      <DivContent id="DivContent">
        {deviceType === DeviceType.Desktop && <Menu />}
        <CustomScrollbar width={"auto"} height={"100%"}>
          <DivMain id="DivMain">{children}</DivMain>
        </CustomScrollbar>
      </DivContent>
      <Footer />
    </DivParent>
  );
}
